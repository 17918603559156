import React, { useEffect, useState, useContext } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import moment from 'moment';

import IconBo from 'assets/img/icons/acms-bo.svg';
import IconMe from 'assets/img/icons/acms-me.svg';
// eslint-disable-next-line
import IconRecruit from 'assets/img/icons/acms-recruit.svg';
import IconProject from 'assets/img/icons/acms-project.svg';
import IconWorkFlow from 'assets/img/icons/acms-work-flow.svg';
import IconDivision from 'assets/img/icons/acms-division.svg';
// eslint-disable-next-line
import IconAccounting from 'assets/img/icons/acms-accounting.svg';
// eslint-disable-next-line
import IconWiki from 'assets/img/icons/acms-wiki.svg';
import IconAaa from 'assets/img/icons/acms-aaa.svg';
import styles from './style.module.scss';
import configs from 'config';
import backGround from 'assets/img/background_min.svg';
import { KEY_OPEN_WHEATHER_MAP } from 'constants/Home';
import { SIX_AM, NOON, SIX_PM } from 'constants/index';
import { ThemContext } from 'layouts/SSO.js';

const Home = () => {
  const { t } = useTranslation();
  const [wheather, setWheather] = useState([]);
  const dataInforUser = useContext(ThemContext);
  useEffect(() => {
    axios
      .get(`https://ipinfo.io/json`)
      .then((res) => {
        axios
          .get(
            `https://api.openweathermap.org/data/2.5/weather?q=${res.data.region}&appid=${KEY_OPEN_WHEATHER_MAP}&units=metric`,
          )
          .then((res) => {
            setWheather(res.data);
          })
          .catch((error) => console.log(error));
      })
      .catch((error) => console.log(error));

    document.body.style = 'background-image:  url(' + backGround + ')';
    document.getElementsByClassName('header') &&
      document.getElementsByClassName('header')[0]?.classList.remove('bg-menu');

    return () => {
      document.body.style = 'background-image:  none';
      document.getElementsByClassName('header') &&
        document.getElementsByClassName('header')[0]?.classList.add('bg-menu');
    };
  }, []);

  const getGreeting = () => {
    if (moment().format('H') >= SIX_AM && moment().format('H') < NOON) {
      return t('home.goodMorning');
    }
    if (moment().format('H') === NOON) {
      return t('home.goodNoon');
    }
    if (moment().format('H') > NOON && moment().format('H') < SIX_PM) {
      return t('home.goodAfternoon');
    }
    return t('home.goodEvening');
  };
  return (
    <Grid item={true} container className={styles.home}>
      <Grid item={true} xs={12} className={styles.weather}>
        <div className={styles.imgCloud}>
          {wheather && wheather?.weather && (
            <img
              className={styles.cloud}
              src={`https://openweathermap.org/img/wn/${wheather?.weather[0]?.icon}@2x.png`}
              alt={`https://openweathermap.org/img/wn/${wheather?.weather[0]?.icon}@2x.png`}
            />
          )}
        </div>
        <div>
          <p className={styles.normal}>
            {getGreeting()} {dataInforUser?.name && dataInforUser?.name}
          </p>
          <p>
            {wheather?.main?.temp && Math.round(wheather?.main?.temp) + '°'}{' '}
            <span>{moment().format('dddd, MMMM DD')}</span>
          </p>
        </div>
      </Grid>
      <div className={styles.borderBottom}></div>
      <Grid item={true} xs={12} className={styles.system}>
        <Grid item={true} xs={12} className={styles.title}>
          <div className={styles.branch}></div>
          <span>{t('home.system')}</span>
        </Grid>
      </Grid>
      <Grid item={true} container xs={12} className={styles.totalSystem}>
        <Grid item={true} xs={12} sm={4} md={3} lg={3} xl={2} className={styles.box}>
          <a href={configs.DOMAIN_AMS_BO} className={styles.redirect} target="blank">
            <img className={styles.logoAcms} src={IconBo} alt={IconBo} />
            <p>{t('home.acmsBo')}</p>
            <p className={styles.description}>{t('home.employee')}</p>
          </a>
        </Grid>
        <Grid item={true} xs={12} sm={4} md={3} lg={3} xl={2} className={styles.box}>
          <a href={configs.DOMAIN_AMS_ME} className={styles.redirect} target="blank">
            <img className={styles.logoAcms} src={IconMe} alt={IconMe} />
            <p>{t('home.acmsMe')}</p>
            <p className={styles.description}>{t('home.employeeGate')}</p>
          </a>
        </Grid>
        <Grid item={true} xs={12} sm={4} md={3} lg={3} xl={2} className={styles.box}>
          <a href={configs.DOMAIN_AMS_WORKFLOW} className={styles.redirect} target="blank">
            <img className={styles.logoAcms} src={IconWorkFlow} alt={IconWorkFlow} />
            <p>{t('home.acmsWorkFlow')}</p>
            <p className={styles.description}>{t('home.createRequest')}</p>
          </a>
        </Grid>
        {/* <Grid item={true} xs={12} sm={4} md={3} lg={3} xl={2} className={styles.box}>
          <a href={configs.DOMAIN_AMS_ACCOUNTING} className={styles.redirect} target="blank">
            <img className={styles.logoAcms} src={IconAccounting} alt={IconAccounting} />
            <p>{t('home.acmsAccounting')}</p>
            <p className={styles.description}>{t('home.account')}</p>
          </a>
        </Grid> */}
        <Grid item={true} xs={12} sm={4} md={3} lg={3} xl={2} className={styles.box}>
          <a href={configs.DOMAIN_AMS_WIKI} className={styles.redirect} target="blank">
            <img className={styles.logoAcms} src={IconWiki} alt={IconWiki} />
            <p>{t('home.acmsWiki')}</p>
            <p className={styles.description}>{t('home.wiki')}</p>
          </a>
        </Grid>
        <Grid item={true} xs={12} sm={4} md={3} lg={3} xl={2} className={styles.box}>
          <a href={configs.DOMAIN_AMS_RECRUIT} className={styles.redirect} target="blank">
            <img className={styles.logoAcms} src={IconRecruit} alt={IconRecruit} />
            <p>{t('home.acmsRecruit')}</p>
            <p className={styles.description}>{t('home.recruitment')}</p>
          </a>
        </Grid>
        <Grid item={true} xs={12} sm={4} md={3} lg={3} xl={2} className={styles.box}>
          <a href={configs.DOMAIN_AMS_PROJECT} className={styles.redirect} target="blank">
            <img className={styles.logoAcms} src={IconProject} alt={IconProject} />
            <p>{t('home.acmsProject')}</p>
            <p className={styles.description}>{t('home.project')}</p>
          </a>
        </Grid>
        <Grid item={true} xs={12} sm={4} md={3} lg={3} xl={2} className={styles.box}>
          <a href={configs.DOMAIN_AMS_DIVISION} className={styles.redirect} target="blank">
            <img className={styles.logoAcms} src={IconDivision} alt={IconDivision} />
            <p>{t('home.acmsDivision')}</p>
            <p className={styles.description}>{t('home.division')}</p>
          </a>
        </Grid>
        <Grid item={true} xs={12} sm={4} md={3} lg={3} xl={2} className={styles.box}>
          <a href={'https://aaa.amela.vn'} className={styles.redirect} target="blank">
            <img className={styles.logoAcms} src={IconAaa} alt={IconAaa} />
            <p>{t('home.acmsAAA')}</p>
            <p className={styles.description}>{t('home.aaa')}</p>
          </a>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Home;
