import { useQuery } from 'react-query';

import { USE_INFOR_USER } from 'constants/Auth';
import { getInforUser } from '../api/auth';
import cookieFC from '../helpers/cookie';
import configs from 'config';

export function useInforAuth() {
  const { data, isLoading, error } = useQuery(USE_INFOR_USER, async () => {
    if(!cookieFC.getToken()){
      window.location.href = configs.API_DOMAIN_LOGIN;
      return;
    }
    const response = await getInforUser();

    return response?.data;
  });

  return { ...data, isLoading, error };
}
